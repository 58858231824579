<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <b-row>
            <v-select
              v-model="voucher_type"
              class="v-select-cust"
              :options="optionsType"
              placeholder="Voucher Type"
              label="title"
              value="value"
            >
            </v-select>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <hr />
    <b-row v-if="voucher_type.value > 0">
      <b-col sm="12">
        <b-card>
          <b-row>
            <b-col sm="12" id="preview" class="mb-3"></b-col>
          </b-row>
          <div slot="header">
            <strong
              >{{
                voucherID ? staticNames.name1 : staticNames.name
              }}&nbsp;Voucher &nbsp;{{
                voucher_type.value == 1 ? "for Movie" : "for Event"
              }}</strong
            >
            <small>Form</small>
          </div>
          <!-- <b-form v-on:submit.prevent.self="submitBannerByMovie"> -->
          <b-row>
            <b-col sm="4">
              <b-form-group>
                <label for="Title">Voucher Code</label>
                <b-form-input
                  required
                  v-model="form.voucherCode"
                  type="text"
                  id="Title"
                  placeholder="Voucher Code"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <label for="Title">Voucher Discount Type</label>
                <multiselect
                  v-model="form.voucher_discount_type"
                  :options="optionsDiscount"
                  :multiple="false"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :select-label="''"
                  :deselect-label="''"
                  track-by="id"
                  label="name"
                  :selectedLabel="''"
                  required
                ></multiselect>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <label for="Title">Voucher Discount</label>
                <b-form-input
                  v-model="form.voucher_discount"
                  type="number"
                  required
                  id="Title"
                  placeholder="Voucher Code"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="4">
              <b-form-group>
                <label for="Title">{{
                  voucher_type.value == 1 ? "Cinema" : "Auditorium"
                }}</label>
                <div v-if="voucher_type.value == 1">
                  <multiselect
                    v-model="form.CinemaID"
                    track-by="CinemaID"
                    label="CinemaName"
                    :options="cinemaList"
                    :multiple="false"
                    :deselect-label="''"
                    :selectedLabel="''"
                  ></multiselect>
                </div>
                <div v-else>
                  <!-- <multiselect
                    v-model="form.CinemaID"
                    track-by="event_venue_id"
                    label="a_name"
                    :options="auditoriumList"
                    :multiple="false"
                    :deselect-label="''"
                    :selectedLabel="''"
                  ></multiselect> -->
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <label for="Title">{{
                  voucher_type.value == 1 ? "Movie" : "Event"
                }}</label>
                <div v-if="voucher_type.value == 1">
                  <multiselect
                    v-model="movieIDArray"
                    :options="activeMovies"
                    track-by="ID"
                    label="Title"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :select-label="''"
                    :deselect-label="''"
                    :selectedLabel="''"
                  ></multiselect>
                </div>
                <div v-else>
                  <multiselect
                    v-model="movieIDArray"
                    :options="eventList"
                    track-by="event_id"
                    label="e_name"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :select-label="''"
                    :deselect-label="''"
                    :selectedLabel="''"
                  ></multiselect>
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <label for="Title">Voucher Available</label>
                <b-form-input
                  v-model="form.voucherLimit"
                  type="number"
                  required
                  id="Voucher Limit"
                  placeholder="Name"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <label for="Title">Voucher Expiry</label>
                <b-form-input
                  v-model="form.voucherExpiry"
                  type="date"
                  required
                  id="Voucher Limit"
                  placeholder="Name"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- <b-col sm="4">
              <b-form-group>
                <label for="Title">Transaction Type</label>
                <multiselect
                  v-model="value"
                  :options="options"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :select-label="''"
                  :deselect-label="''"
                  :selectedLabel="''"
                ></multiselect>
              </b-form-group>
            </b-col> -->
            <b-col sm="4">
              <b-form-group>
                <label for="Title">No of Uses per customer</label>
                <b-form-input
                  v-model="form.voucherUsePerCust"
                  type="text"
                  required
                  id="Title"
                  placeholder="Name"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group>
                <label for="Title">Day of the week specific</label>
                <multiselect
                  v-model="voucherDaysArray"
                  :options="optionsDays"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :select-label="''"
                  :deselect-label="''"
                  track-by="id"
                  label="id"
                  :selectedLabel="''"
                ></multiselect>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group></b-form-group>
              <label for="title">Voucher Active</label>
              <c-switch
                class="mx-1"
                v-model="form.voucherActive"
                color="primary"
                variant="3d"
                label
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="3">
              <b-button @click="addVoucher" size="sm" variant="primary">
                <i class="fa fa-dot-circle-o"></i>

                {{ voucherID ? staticNames.name1 : staticNames.name }}
              </b-button>
            </b-col>
            <!-- <b-col sm="3">
              <b-button size="sm" variant="success">
                <i class="fa fa-dot-circle-o"></i> Reset
              </b-button>
            </b-col> -->
          </b-row>
          <!-- </b-form> -->
        </b-card>
      </b-col>
    </b-row>
    <hr />
    <b-row v-if="voucherID" class="mt-2">
      <b-col sm="12">
        <div slot="header">
          <strong> Send Voucher To User</strong>
        </div>
        <b-card class="mt-2">
          <b-row>
            <b-col sm="4">
              <b-form-group>
                <label for="Title">User Email</label>
                <multiselect
                  v-model="userIdArray"
                  :options="allUsers"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :select-label="''"
                  :deselect-label="''"
                  track-by="ID"
                  label="Email"
                  :selectedLabel="''"
                ></multiselect>
              </b-form-group>
              <b-button
                :disabled="disableSent == true ? true : false"
                size="sm"
                @click="sentVoucherUser"
                variant="primary"
              >
                <b-spinner v-if="disableSent == true" small></b-spinner> &nbsp;
                <i class="fa fa-dot-circle-o"></i>

                Send Voucher
              </b-button>
            </b-col>
            <b-col sm="8">
              <label for="Title">Voucher Sent to</label>

              <v-client-table
                :columns="columns"
                :data="allVoucherEmailedUsers"
                :options="options"
                :theme="theme"
                id="dataTable"
              >
              </v-client-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MovieService from "@/services/MovieService";
import MasterService from "@/services/MasterService";
import { Switch as cSwitch } from "@coreui/vue";
import CinemaService from "@/services/CinemaService";

export default {
  name: "add-voucher",

  components: {},
  data() {
    return {
      voucher_type: 0,
      columns: ["email_id", "sent_date", "mail_sent"],
      optionsType: [
        { title: "Movie", value: 1 },
        { title: "Event", value: 2 },
      ],
      options: {
        headings: {
          Title: "Voucher",
          // Runtime: "Runtime",
          // Genre: "Genre",
          // Released: "Released",
          // MovieIsActive: "Status",
          ID: "Edit",
        },
        sortable: ["email_id"],
        filterable: ["email_id"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      form: {
        voucherCode: "",
        CinemaID: null,
        movieID: null,
        voucherDays: "",
        voucherLimit: 0,
        voucherExpiry: "",
        voucherUsePerCust: 0,
        voucherActive: 0,
        voucher_discount: 0,
        voucher_discount_type: null,
      },
      voucherID: null,
      cinemaList: [],
      auditoriumList: [],
      eventList: [],
      allVoucherEmailedUsers: [],
      voucherDaysArray: [],
      allUsers: [],
      userIdArray: [],

      disableSent: false,

      movieIDArray: [],
      activeMovies: [],
      staticNames: {
        name: "Add",
        name1: "Update",
      },
      value: null,
      optionsDays: [
        {
          id: "Sun",
          name: "Sun",
        },
        {
          id: "Mon",
          name: "Mon",
        },
        {
          id: "Tue",
          name: "Tue",
        },
        {
          id: "Wed",
          name: "Wed",
        },
        {
          id: "Thu",
          name: "Thu",
        },
        {
          id: "Fri",
          name: "Fri",
        },
        {
          id: "Sat",
          name: "Sat",
        },
      ],

      optionsDiscount: [
        // {
        //   id: "1",
        //   name: "Flat Amount",
        // },
        {
          id: "2",
          name: "Percentage",
        },
      ],
    };
  },
  components: {
    cSwitch,
  },
  computed: {},
  watch: {},
  beforeMount() {
    this.voucherID = this.$route.query.ID;

    this.getCinemasList("0");
    this.getMovies();
  },
  mounted: function () {
    if (this.voucherID) {
      this.getVoucher(this.voucherID);
    }
  },
  methods: {
    sentVoucherUser: function () {
      if (this.userIdArray.length <= 0) {
        return false;
      }
      this.disableSent = true;
      this.form.voucher_discount_type = this.form.voucher_discount_type.id;
      this.form.CinemaID = this.form.CinemaID.CinemaID;
      this.form.movieID = this.movieIDArray.map((u) => u.ID).join(",");
      this.form.voucherDays = this.voucherDaysArray.map((u) => u.id).join(",");
      this.form.voucherActive = this.form.voucherActive ? 1 : 0;

      let payload = {
        voucher_discount_type: this.form.voucher_discount_type,
        voucher_discount: this.form.voucher_discount,
        cinema_ids: this.form.CinemaID,
        movie_ids: this.form.movieID,
        voucher_code: this.form.voucherCode,
        voucher_limit: this.form.voucherLimit,
        voucher_expiry: this.form.voucherExpiry,
        voucher_trans_type: "1",
        no_of_uses_per_cust: this.form.voucherUsePerCust,
        voucher_valid_days: this.form.voucherDays,
        voucher_isactive: this.form.voucherActive,
        voucher_id: this.voucherID ? this.voucherID : 0,
        userIdArray: this.userIdArray,
      };
      MasterService.sendVouchers(payload)
        .then((response) => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";

          if (data.Status) {
            this.userIdArray = [];
          }

          this.getVoucher(this.voucherID);
          // this.$router.push({
          //   path: "/movies/voucherlist",
          // });
        })
        .catch((error) => {
          this.disableSent = false;
          console.log("Catch on addvoucher Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    addVoucher: function () {
      this.form.movieID = this.movieIDArray;
      console.log(
        "object :>> ",
        !this.form.voucher_discount_type,
        !this.form.voucher_discount,
        !this.form.CinemaID,
        !this.form.movieID,
        !this.form.voucherCode
      );
      let bol = false;
      if (this.voucher_type == 1) {
        if (
          !this.form.voucher_discount_type ||
          !this.form.voucher_discount ||
          !this.form.CinemaID ||
          !this.form.movieID ||
          !this.form.voucherCode
        ) {
          bol = true;
        }
      } else {
        if (
          !this.form.voucher_discount_type ||
          !this.form.voucher_discount ||
          !this.form.movieID ||
          !this.form.voucherCode
        ) {
          bol = true;
        }
      }
      if (bol) {
        console.log("Enter Details=>", this.movieIDArray);
        console.log("Enter Details=>", this.form);
      } else {
        this.form.voucher_discount_type = this.form.voucher_discount_type.id;
        if (this.voucher_type.value == 1) {
          this.form.CinemaID = this.form.CinemaID.CinemaID;
        } else {
          this.form.CinemaID = "";
        }
        if (this.voucher_type.value == 1) {
          this.form.movieID = this.movieIDArray.map((u) => u.ID).join(",");
        } else {
          this.form.movieID = this.movieIDArray
            .map((u) => u.event_id)
            .join(",");
        }
        this.form.voucherDays = this.voucherDaysArray
          .map((u) => u.id)
          .join(",");
        this.form.voucherActive = this.form.voucherActive ? 1 : 0;

        let payload = {
          voucher_discount_type: this.form.voucher_discount_type,
          voucher_discount: this.form.voucher_discount,
          cinema_ids: this.form.CinemaID,
          movie_ids: this.form.movieID,
          voucher_code: this.form.voucherCode,
          voucher_limit: this.form.voucherLimit,
          voucher_expiry: this.form.voucherExpiry,
          voucher_trans_type: "1",
          no_of_uses_per_cust: this.form.voucherUsePerCust,
          voucher_valid_days: this.form.voucherDays,
          voucher_isactive: this.form.voucherActive,
          voucher_id: this.voucherID ? this.voucherID : 0,
          voucher_type: this.voucher_type ? this.voucher_type : 0,
        };
        MasterService.addVoucher(payload)
          .then((response) => {
            const { data } = response;
            data.Message ? this.showMessageSnackbar(data.Message) : "";

            if (data.Status) {
            }
            this.$router.push({
              path: "/movies/voucherlist",
            });
          })
          .catch((error) => {
            console.log("Catch on addvoucher Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },

    getVoucher(ID) {
      setTimeout(() => {
        // let res = await MovieService.getMovies();
        // this.data = res.data;
        this.voucherDaysArray = [];
        this.allUsers = [];
        this.allVoucherEmailedUsers = [];
        this.movieIDArray = [];
        let payload = {
          voucher_id: ID,
        };
        this.data = [];

        MasterService.getAllVouchers(payload)
          .then((response) => {
            const { data } = response;
            console.log("first", data.data);

            this.voucher_type = {
              value: data.data[0].voucher_type,
              title: data.data[0].voucher_type == 1 ? "Movie" : "Event",
            };

            if (data.Status && data.allUsers.length > 0) {
              this.allUsers = data.allUsers;
            } else {
              this.allUsers = [];
            }

            if (data.Status && data.allVoucherEmailedUsers.length > 0) {
              this.allVoucherEmailedUsers = data.allVoucherEmailedUsers;
            } else {
              this.allVoucherEmailedUsers = [];
            }

            if (data.Status && data.data.length > 0) {
              let moviersStr = data.data[0].movie_ids.split(",");
              if (data.data[0].voucher_type == 1) {
                for (let i = 0; i < moviersStr.length; i++) {
                  let filteredMvi = this.activeMovies.filter((x) => {
                    return x.ID == moviersStr[i];
                  });
                  if (filteredMvi.length > 0) {
                    this.movieIDArray.push(...filteredMvi);
                  }
                }
              } else {
                for (let i = 0; i < moviersStr.length; i++) {
                  let filteredMvi = this.eventList.filter((x) => {
                    return x.event_id == moviersStr[i];
                  });
                  if (filteredMvi.length > 0) {
                    this.movieIDArray.push(...filteredMvi);
                  }
                }
              }

              let daysStr = data.data[0].voucher_valid_days.split(",");

              for (let i = 0; i < daysStr.length; i++) {
                let filteredDays = this.optionsDays.filter((x) => {
                  return x.id == daysStr[i];
                });
                if (filteredDays.length > 0) {
                  this.voucherDaysArray.push(...filteredDays);
                }
              }

              if (data.data[0].voucher_type == 1) {
                let filteredCinema = this.cinemaList.filter((x) => {
                  return x.CinemaID == data.data[0].cinema_ids;
                });
                if (filteredCinema.length > 0) {
                  this.form.CinemaID = filteredCinema[0];
                }
              } else {
                let filteredCinema = this.auditoriumList.filter((x) => {
                  return x.auditorium_id == data.data[0].cinema_ids;
                });
                console.log("filteredCinema", filteredCinema.length);
                if (filteredCinema.length > 0) {
                  console.log("first", filteredCinema);
                  this.form.CinemaID = filteredCinema[0];
                }
                console.log("this.form.CinemaID ", this.form.CinemaID);
              }

              let filteredDType = this.optionsDiscount.filter((x) => {
                return x.id == data.data[0].voucher_discount_type;
              });
              if (filteredDType.length > 0) {
                this.form.voucher_discount_type = filteredDType[0];
              }

              this.form.voucherCode = data.data[0].voucher_code;
              this.form.voucher_discount = data.data[0].voucher_discount;

              this.form.voucherLimit = data.data[0].voucher_limit;
              this.form.voucherExpiry = new Date(
                data.data[0].voucher_expiry
              ).toLocaleDateString("af-ZA");
              // alert(this.form.voucherExpiry);
              // "2022-09-14";
              this.form.voucherUsePerCust = data.data[0].no_of_uses_per_cust;
              this.form.voucherActive =
                data.data[0].voucher_isactive == "1" ? true : false;
            } else {
              this.showMessageSnackbar(data.Message);
            }
            this.disableSent = false;
          })
          .catch((error) => {
            this.disableSent = false;
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }, 2000);
    },

    showMessageSnackbar: function (Message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = Message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
    getCinemasList: function (OrgID) {
      this.cinemaList = [];
      let payload = {
        OrgID,
      };
      CinemaService.getActiveCinemas(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status && data.CinemaList.length > 0) {
            this.cinemaList = data.CinemaList;
            // this.userObject.cinemaList = data.CinemaList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          console.log("Catch on AddUser-getCinemasDDL Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
      MasterService.getAllAuditorium().then((response) => {
        console.log("response", response);
        if (response.error) {
          this.showMessageSnackbar(response.error);
        } else {
          // const { data } = response;
          this.auditoriumList = response.data.data;
          console.log("auditoriumList", this.auditoriumList);
        }
      });

      MasterService.getAllPageEvent().then((response) => {
        console.log("response", response);
        if (response.error) {
          this.showMessageSnackbar(response.error);
        } else {
          // const { data } = response;
          this.eventList = response.data.data;
          console.log("auditoriumList", this.eventList);
        }
      });
    },
    getMovies() {
      // let res = await MovieService.getMovies();
      // this.data = res.data;
      this.activeMovies = [];
      MovieService.getActiveMovies()
        .then((response) => {
          const { data } = response;
          if (data.Status && data.MovieList.length > 0) {
            this.activeMovies = data.MovieList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
  },
  updated() {},
};
</script>

<style></style>
